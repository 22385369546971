

import moment from 'moment';
import { defineComponent, reactive, toRefs, ref, onMounted, watch,  } from "vue";
import { ContentLoader } from 'vue-content-loader';
import store from "@/store/index";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import useAlert from "@/composables/Alert.ts";
import { hideModal } from '@/core/helpers/dom';
import { grupodelete } from "@/services/GrupoService";
import useEmitter from '@/composables/Emmiter'

export default {
  name: "deletGrupoModal",
  props: {
     dadosGrupo: {
      type: Object,
      required: true,
    }, 
  },

  components:{
	  ContentLoader
  },

  emits: ["atualizar"],

  setup(props, { emit }) {

  let logs = ref({});
  let codConcessionaria = ref();
	let animate = ref(true);
  const {  showTimeAlert } = useAlert();

    watch(() => props.dadosGrupo, () => {   
      logs.value = props.dadosGrupo
    });

    async function deletConcessionaria(dadosGrupo) {
      animate.value = true
      await grupodelete(dadosGrupo).then(() => {
        const elementModal = document.getElementById("kt_modallog_1");
        hideModal(elementModal);    
        showTimeAlert("Grupo excluído com sucesso");
        emit("atualizar");
      }).catch((e) => {
        showTimeAlert("Não foi possível completar a solicitação", "error");
      });
      animate.value = false
    };

    function deletarConcessionaria() {
      if (props.dadosGrupo) {
        Swal.fire({
          title: "Confirmação de alteração",
          text: "Essa alteração não poderá ser desfeita!",
          showCancelButton: true,
          confirmButtonText: "Excluir Grupo",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
             confirmButton: "btn btn-flex background_btn_1_opct text-white",
             cancelButton: "btn btn-light me-3",
          },
          reverseButtons: true,
        }).then((result) => {

          if (result.isConfirmed) {
            deletConcessionaria(props.dadosGrupo?.codGrupo);
          } 
        });
      }
    } 
 
    return {
      logs,
      animate,
      codConcessionaria,  
      deletarConcessionaria
      
    };
  },
};

