
import Datatable from "@/components/datatable/Datatable.vue";
import { defineComponent, reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ContentLoader } from "vue-content-loader";
import { grupolist } from "@/services/GrupoService";
import DeletGrupos from "./components/DeletGrupos.vue";
import useEmitter from "@/composables/Emmiter";

export default defineComponent({
  name: "concessionariaadd",

  components: {
    DeletGrupos,
    Datatable,
    ContentLoader,
  },

  setup() {
    const data: any = reactive({
      info: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 1,
        last_page: 3,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 50,
        prev_page_url: null,
        to: 0,
        total: 0,
      },
      nome: "",
      responsavel: "",
    });

    let page = ref(1);
    const concessionaria: any = ref([]);
    const router = useRouter();
    const contagempagina = 20;
    const contagemArray = 30;
    let animate = ref(true);
    const emitter = useEmitter();
    const dadosGrupo: any = ref([]);
    const auxdadosGrupo: any = ref([]);
    const fastFilter: any = ref("");
    const codconcessionaria: any = ref({});
    const codMarcaselect: any = ref({});
    const filtroConsultor: any = ref({});
    const filtroCodatria: any = ref({});
    const dadosgrupoespecifico = ref({});
    const codGrupo: any = ref([]);
    const grupo: any = ref([]);
    let postData: any = ref({});

    onMounted(async () => {
      listaGrupo();
    });

    emitter.on("filtrar-crud-grupo", async (data) => {
      animate.value = true;
      postData.value = {
        dadosGrupo: data.filtrosServicos.dadosGrupo,
      };

      await listaGrupo();
      animate.value = false;
    });

    function chamarespecifico(grupo) {
      dadosgrupoespecifico.value = grupo;
    }

    function goToParams(routeName, grupoRef) {
      router.push({
        name: routeName,
        params: {
          codGrupo: grupoRef.codGrupo,
        },
      });
    }

    const delayTimer = ref();

    function filterData() {
      clearTimeout(delayTimer.value);
      delayTimer.value = setTimeout(function () {
        trataInfo(1, true);
      }, 300);
    }

    async function listaGrupo() {
      animate.value = true;
      let response = await grupolist(postData.value);
      codGrupo.value = response;
      auxdadosGrupo.value = [...response];
      trataInfo(1);
      fastFilter.value = "";
      animate.value = false;
    }

    function trataInfo(page = 1, filter = false) {
      let basedadosGrupo = [];

      if (filter && fastFilter.value) {
        basedadosGrupo = auxdadosGrupo.value.filter(
          (grupo) =>
            grupo.nome.toLowerCase().includes(fastFilter.value.toLowerCase()) ||
            grupo.responsavel
              .toLowerCase()
              .includes(fastFilter.value.toLowerCase())
        );
      } else {
        basedadosGrupo = auxdadosGrupo.value;
      }
      data.info.total = basedadosGrupo.length;
      data.info.last_page =
        Math.ceil(basedadosGrupo.length / data.info.per_page) ?? 0;
      data.info.current_page = page;
      const start = data.info.per_page * (page - 1);
      const end = data.info.per_page * page;
      if (data.info.last_page == page) {
        data.info.to = basedadosGrupo.length;
      } else if (end > basedadosGrupo.length) {
        data.info.to = basedadosGrupo.length;
      } else {
        data.info.to = end;
      }
      data.info.data = basedadosGrupo.slice(start, end);
    }

    function goTo(url) {
      router.push(url);
    }

    return {
      concessionaria,
      goTo,
      codGrupo,
      data,
      animate,
      page,
      contagempagina,
      contagemArray,
      dadosGrupo,
      chamarespecifico,
      dadosgrupoespecifico,
      goToParams,
      codconcessionaria,
      codMarcaselect,
      filtroConsultor,
      filtroCodatria,
      fastFilter,
      trataInfo,
      filterData,
      postData,
      listaGrupo,
      grupo,
    };
  },
});
